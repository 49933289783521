import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import InputError from '../components/InputError';
// import SocketContext from '../context';
import { API } from "./AppConfig";


const CategoryForm = props => {
  // const socket = useContext(SocketContext);
  const nameRef = useRef();

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const saveForm = async data => {
    data.active = data.active === 'true' ? true : false;

    if (props.hideType) {
      data.type = props.data.type;
      data.active = props.data.active;
    }

    data.user = props.user.id;
    data.upsert = true;
    if (props.saveMode === 'edit') {
      data.id = props.data.id;
    }

    const response = await fetch(`${API}/categories/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + props.user.token
      },

      body: JSON.stringify(data),
      redirect: 'follow'
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
    } else {
      await response.json();
      props.addedCategory(data.name);
      props.setShowModal(false);
    }
    return;
  };

  return (
    <form className='min-h-full h-auto' onSubmit={handleSubmit(saveForm)}>
      <div>
        <div className='mt-2 pt-2'>
          <div className='mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label
                htmlFor='name'
                className='block text-sm font-medium leading-5 text-gray-700'>
                Category Name
              </label>
              <div className='mt-1 rounded-md shadow-sm'>
                <input
                  defaultValue={props.data.name}
                  id='name'
                  type='text'
                  {...register("name", { required: {
                    value:true,
                    message:"Category name is required"
                  }})}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:${
                    errors.name ? `border-red-300` : `border-blue-300`
                  } transition duration-150 ease-in-out sm:text-sm sm:leading-5 
                  ${errors.name ? 'border-red-500' : ''}`}
                />               
              </div>
              {errors.name && <InputError error={errors.name} />}
            </div>
            {!props.hideType && (
              <div className='sm:col-span-6'>
                <label
                  htmlFor='type'
                  className='block text-sm font-medium leading-5 text-gray-700'>
                  Type
                </label>
                <div className='mt-1 rounded-md shadow-sm'>
                  <select
                    defaultValue={props.data.type}
                    {...register("type")}
                    name='type'
                    id='type'
                    className='form-select block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
                    <option value='Senders'>Senders</option>
                    <option value='Recipients'>Recipients</option>
                  </select>
                </div>
              </div>
            )}
            {!props.hideType && (
              <div className='sm:col-span-6'>
                <label
                  htmlFor='type'
                  className='block text-sm font-medium leading-5 text-gray-700'>
                  Status
                </label>
                <div className='mt-1 rounded-md shadow-sm'>
                  <select
                    defaultValue={
                      props.data && props.data.active ? props.data.active : true
                    }
                    {...register("active")}
                    name='active'
                    id='active'
                    className='form-select block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mt-8 border-t border-gray-200 pt-5'>
        <div className='flex justify-end'>
          <span className='inline-flex rounded-md shadow-sm'>
            <button
              onClick={() => {
                props.setShowModal(false);
              }}
              type='button'
              className='py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out'>
              Cancel
            </button>
          </span>
          <span className='ml-3 inline-flex rounded-md shadow-sm'>
            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] focus:outline-none transition duration-150 ease-in-out'>
              Save
            </button>
          </span>
        </div>
      </div>
    </form>
  );
};

export default CategoryForm;
