/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import SocketContext from '../context';
import { API } from "./AppConfig";
const Login = ({ setOpen, setIsLoggedIn, setOpenProfile, setOpenRegister, setOpenForgot}) => {
  const socket = useContext(SocketContext);
  const [loginState, setLoginState] = useState();

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const userLogin = async data => {
    const response = await fetch(`${API}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: data.email, password: data.password })
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      setLoginState('Invalid Username/Password');
    } else {
      const {data:resData} = await response.json();
      console.log("resData",resData)
      setLoginState(undefined);
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: resData._id,
          token: resData.token,
          email: resData.email,
          name: resData.firstname + ' ' + resData.lastname,
          firstname: resData.firstname,
          lastname: resData.lastname,
          settings: resData.settings,
          plan:resData.plan
        })
      ); 
      // socket.emit('login', { user: resData._id });
      setIsLoggedIn(true);
    }
  };


  return (
    <div className='fixed flex justify-center items-center z-50 bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] bg-white shadow-lg w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen'>
      <div className='max-w-sm w-full'>
        {loginState !== undefined && (
          <div
            className='bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 my-3'
            role='alert'>
            <p className='font-bold'>{loginState}</p>
            <p id='invalid-msg' className='text-sm'></p>
          </div>
        )}
        <form
          onSubmit={handleSubmit(userLogin)}
          className='bg-white shadow-md rounded px-8 pb-8 pt-4 mb-4'>
            <div className="flex items-start justify-between py-2 rounded-t ">
            <h3 className="text-xl font-semibold text-orange-500">
           Login
            </h3>
          </div>
          <div className='mb-2'>
            <label
              className='block text-orange-500 text-sm font-bold mb-2'
              htmlFor='email'>
              Email
            </label>
            <input
              className={
                errors.email
                  ? 'border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  : 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              }
              id='email'
              type='text'
              placeholder='Email'
              name='email'
              {...register("email", { required: true, pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address'
              } })}
            />
            <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.email ? errors.email.message : " "}
              </p>
          </div>
          <div className='mb-4'>
            <label
              className='block text-orange-500 text-sm font-bold mb-2'
              htmlFor='password'>
              Password
            </label>
            <input
              className={
                errors.password
                  ? 'border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline'
                  : 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline'
              }
              id='password'
              name='password'
              type='password'
              placeholder='******************'
              {...register("password",{
                required: 'Required',
                maxLength: {
                  value: 20,
                  message: 'Maximum 20 characters'
                },
                minLength: {
                  value: 6,
                  message: 'Minimum 6 characters'
                }
              })}
            />
            
              <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.password ? errors.password.message : " "}
              </p>
        
          </div>
          <div className='flex items-center justify-between'>
            <button
              className='bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='submit'>
              Login
            </button>
            <a
              className='inline-block align-baseline font-bold text-sm text-orange-500 hover:text-orange-800'
              href='#'
              onClick={()=>{
                setOpen(false)
                // setOpenRegister(false)
                setOpenForgot(true)
              }}
              >
              Forgot Password?
            </a>
          </div>
          <a
              className='inline-block align-baseline font-bold text-sm text-orange-500 hover:text-orange-800 mt-2'
              href='#'
              onClick={()=>{
                setOpen(false)
                setOpenRegister(true)}}
              >
              Don't have an account? Create One
            </a>
        </form>
      </div>
    </div>
  );
};

export default Login;
