/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import SocketContext from "../context";
import { API } from "./AppConfig";
const Register = ({ setIsLoggedIn, setOpen, setOpenRegister }) => {
  const [loginState, setLoginState] = useState();
  const socket = useContext(SocketContext);

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const userRegister = async (data) => {
    const response = await fetch(`${API}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firstname: data.firstname,lastname: data.lastname,email: data.email, password: data.password }),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      setLoginState("Sign Up failed");
    } else {
      const { data: resData } = await response.json();
      console.log("resData", resData);
      setLoginState(undefined);

      
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: resData._id,
          token: resData.token,
          email: resData.email,
          name: resData.firstname + " " + resData.lastname,
          firstname: resData.firstname,
          lastname: resData.lastname,
          settings: resData.settings,
          plan: resData.plan
        })
      );

      const selectedplan = localStorage.getItem("selectedplan");

      if(selectedplan){
        const plan = JSON.parse(selectedplan)
        const link = plan.paylink+"?prefilled_email="+resData.email;

        if(link !== ""){
        window.location.replace(link);
        return;
        }
      }

      // socket.emit("login", { user: resData._id });
      setIsLoggedIn(true);
    }
  };
  return (
    <div className="fixed flex justify-center items-center z-50 bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] shadow-lg w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen">
      
      <div className="max-w-sm w-full">
        {loginState !== undefined && (
          <div
            className="bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 my-3"
            role="alert"
          >
            <p className="font-bold">{loginState}</p>
            <p id="invalid-msg" className="text-sm"></p>
          </div>
        )}
        <form
          onSubmit={handleSubmit(userRegister)}
          className="bg-white shadow-md rounded px-8 pb-8 pt-4 mb-4"
        >
           <div className="flex items-start justify-between py-2 rounded-t ">
            <h3 className="text-xl font-semibold text-orange-500">
           Sign Up
            </h3>
            {/* <button
              type="button"
              onClick={() => setOpenRegister(false)}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="usl-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button> */}
          </div>
          <div >
            <label
              className="block text-orange-500 text-sm font-bold mb-2"
              htmlFor="firstname"
            >
              First Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstname"
              type="text"
              placeholder="First Name"
              name="firstname"
             {...register("firstname",{
                required: "Required",
              })}
            />
            <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.firstname ? errors.firstname.message : " "}
              </p>
          </div>
          
          <div >
            <label
              className="block text-orange-500 text-sm font-bold mb-2"
              htmlFor="lastname"
            >
              Last Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastname"
              type="text"
              placeholder="Last Name"
              name="lastname"
              {...register("lastname",{
                required: "Required",
              })}
            />
            <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.lastname ? errors.lastname.message : " "}
              </p>
          </div>
          <div >
            <label
              className="block text-orange-500 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={
                errors.email
                  ? "border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              }
              id="email"
              type="text"
              placeholder="Email"
              name="email"
            {...register("email",{
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
             <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.email ? errors.email.message : " "}
              </p>
          </div>
          <div className="mb-4">
            <label
              className="block text-orange-500 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={
                errors.password
                  ? "border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                  : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
              }
              id="password"
              name="password"
              type="password"
              placeholder="******************"
             {...register("password",{
                required: "Required",
                maxLength: {
                  value: 20,
                  message: "Password should be maximum 20 chars in length",
                },
                minLength: {
                  value: 6,
                  message: "Password should be minimum 6 chars in length",
                },
              })}
            />
            <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.password ? errors.password.message : " "}
              </p>
          </div>
          <div className="flex justify-center">
            <button
              className="bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign Up
            </button>
            <a
              className="mx-2 inline-block align-baseline font-bold text-sm text-orange-500 hover:text-orange-800 mt-2"
              href="#"
              onClick={() => {
                setOpen(true);
                setOpenRegister(false);
              }}
            >
              Have an account? Login
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
