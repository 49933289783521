import React from 'react';

const Search = ({ search, setSearch, setStart }) => {
  return (
    <div>
      <div className='mt-2 mr-2 relative p-2 shadow-sm border-2 border-gray-400 rounded-lg shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          <svg
            className='h-4 w-4 text-gray-800'
            fill='currentColor'
            viewBox='0 0 20 20'>
            <path
              className='heroicon-ui'
              d='M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'
            />
          </svg>
        </div>
        <input
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setStart(0);
          }}
          id='search'
          className='form-input block w-full pl-10 text-md sm:leading-5 focus:outline-none'
          placeholder='Search'
          style={{fontFamily:"Inter"}}
        />
      </div>
    </div>
  );
};

export default Search;
