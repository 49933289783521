import React, { useState } from 'react';
// import moment from 'moment';
import { useForm } from 'react-hook-form';
import InputError from '../components/InputError';
import Alert from '../components/Alert';
import { API } from "./AppConfig";

const UserSettings = props => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [type, setType] = useState('success');
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const saveForm = async data => {
    data.id = user.id;
    // data.upsert = true;
    const settings = user && user.settings ? user.settings : {};
    data.settings = { ...settings, listlimit: parseInt(data.listlimit) };
    delete data['listlimit'];
    if (data.password === '') {
      delete data['password'];
    }

    const response = await fetch(`${API}/users/${user.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + props.user.token
      },

      body: JSON.stringify(data),
      redirect: 'follow'
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      setType('danger');
      setOpen(true);
      setMsg('Cannot save profile!');
    } else {
      const user = await response.json();
      if (user) {
        setType('success');
        setOpen(true);
        setMsg('Profile Saved!');
      }
    }
    return;
  };

  return (
    <div className='h-screen'>
      <div>
        <Alert type={type} msg={msg} show={open} setOpen={setOpen} />
      </div>

      <div className='mt-12 mx-10 bg-white px-4 py-3 sm:rounded-lg sm:p-6'>
        <div className='md:grid md:grid-cols-6 md:gap-6'>
          
          <div className='mt-2 md:mt-0 md:col-span-2'>
            <form action='#' onSubmit={handleSubmit(saveForm)}>
              <div className='grid grid-cols-6 gap-3'>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='firstname'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    First name
                  </label>
                  <input
                    defaultValue={user.firstname}
                    {...register("firstname",{
                      required: 'First name is required'
                    })}
                    id='firstname'
                    name='firstname'
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:${
                    errors.firstname ? `border-red-300` : `border-blue-300`
                  } transition duration-150 ease-in-out sm:text-sm sm:leading-5 
                  ${errors.firstnamea ? 'border-red-500' : ''}`}
                  />
                  {errors.firstname && <InputError error={errors.firstname} />}
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='lastname'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    Last name
                  </label>
                  <input
                    defaultValue={user.lastname}
                    {...register("lastname",{
                      required: 'Last name is required'
                    })}
                    id='lastname'
                    name='lastname'
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:${
                    errors.lastname ? `border-red-300` : `border-blue-300`
                  } transition duration-150 ease-in-out sm:text-sm sm:leading-5 
                  ${errors.lastname ? 'border-red-500' : ''}`}
                  />
                  {errors.lastname && <InputError error={errors.lastname} />}
                </div>

                <div className='col-span-6'>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    Email address
                  </label>
                  <input
                    defaultValue={user.email}
                    id='email'
                    name='email'
                    type='email'
                    {...register("email",{
                      required: 'Email is required'
                    })}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:${
                    errors.email ? `border-red-300` : `border-blue-300`
                  } transition duration-150 ease-in-out sm:text-sm sm:leading-5 
                  ${errors.email ? 'border-red-500' : ''}`}
                  />
                  {errors.email && <InputError error={errors.email} />}
                </div>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='listlimit'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    Default List Limit
                  </label>
                  <select
                    defaultValue={user && user.settings && user.settings.listlimit ? 10 : 0}
                    id='listlimit'
                    name='listlimit'
                    {...register("listlimit")}
                    className='mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </div>
              </div>

              <div className='py-3 bg-gray-50 text-left '>
                <span className='inline-flex rounded-md shadow-sm'>
                  <button
                    type='submit'
                    className='inline-flex justify-center py-2 px-4 mr-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] focus:outline-none transition duration-150 ease-in-out'>
                    Save
                  </button>
                </span>
                <span className='inline-flex rounded-md shadow-sm'>
                  <button
                    onClick={() => {
                      props.setPage('orders');
                      props.setPageTitle('Orders');
                    }}
                    type='button'
                    className='inline-flex items-center py-2 px-4 border border-gray-300 text-xs leading-5  rounded-md text-gray bg-white hover:text-gray-500 focus:outline-none focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150'>
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
