
export const vh = (value) => {
  return `${(100 * value) / window.innerHeight}vh`;
};

export const vw = (value) => {
  return `${(100 * value) / window.innerWidth}vw`;
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const percentDiff = (o, n) => {
  const d = n - o;
  return ((d / o) * 100).toFixed(2);
};

export const toFirstCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

//   function vhToPixel(value) {
//     return document.querySelector("#vhToPixel").innerHTML = `Result = ${(window.innerHeight * value) / 100}px`;
//   }
//   function vwToPixel(value) {
//     return document.querySelector("#vwToPixel").innerHTML = `Result = ${((window.innerWidth * value) / 100)}px`;
//   }
const Util = {
  vh,
  vw,
};

export default Util;
