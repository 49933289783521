import React, { useEffect } from 'react';

const Alert = ({ type, msg, show, setOpen, timeout = 3000 }) => {
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg]);

  if (!show) {
    return null;
  }

  let color = 'gray';

  if (type === 'success') {
    color = 'green';
  } else if (type === 'danger') {
    color = 'red';
  }

  return (
    <div className='rounded-md bg-white p-4 mt-2 mb-2'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <svg
            className={`h-8 w-8 text-${color}-400`}
            fill='currentColor'
            viewBox='0 0 20 20'>
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='ml-3'>
          <p id='alert-msg' className={`text-sm leading-5 font-medium text-${color}-800 m-1`}>
            {msg}
          </p>
        </div>
        <div className='ml-auto pl-3'>
          <div className='-mx-1.5 -my-1.5'>
            <button
              onClick={() => {
                setOpen(false);
              }}
              className={`inline-flex rounded-md p-1.5 text-${color}-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150`}>
              <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
