import React, { useState } from "react";
// import moment from 'moment';
import { useForm } from "react-hook-form";
// import InputError from '../components/InputError';
import Alert from "../components/Alert";
import { API } from "./AppConfig";

const ChangeUserPassword = (props) => {
  const user = props.user;
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("success");
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const saveForm = async (data) => {
    data.id = user.id;
    // data.upsert = true;
    const settings = user && user.settings ? user.settings : {};
    data.settings = { ...settings, listlimit: parseInt(data.listlimit) };
    delete data["listlimit"];
    if (data.password === "") {
      delete data["password"];
    }

    const response = await fetch(`${API}/users/${user.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.user.token,
      },

      body: JSON.stringify(data),
      redirect: "follow",
    });

    if (!response.ok) {
      setType("danger");
      setOpen(true);
      setMsg("Invalid old password.");
    } else {
      await response.json();
      setType("success");
      setOpen(true);
      setMsg("Password Changed Successfully!");
    }
    return;
  };

  return (
    <div className="h-screen rounded-b-lg">
      <div>
        <Alert type={type} msg={msg} show={open} setOpen={setOpen} />
      </div>

      <div className="mt-12 mx-20 bg-white p-4 rounded-lg">
        <div className="md:grid md:grid-cols-10 md:gap-6">
          {/* <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Change Password
            </h3>
          </div> */}
          <div className="mt-2 md:mt-0 md:col-span-2">
            <form action="#" onSubmit={handleSubmit(saveForm)}>
              <div className="grid grid-cols-6 gap-3">
                <div className="col-span-6">
                  <label
                    htmlFor="newpassword"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Enter Old Password
                  </label>
                  <input
                    defaultValue=""
                    id="password"
                    name="password"
                    type="password"
                    {...register("password", {
                      required: false,
                    })}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="grid grid-cols-6 gap-3 mt-3">
                <div className="col-span-6">
                  <label
                    htmlFor="newpassword"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    New Password
                  </label>
                  <input
                    defaultValue=""
                    id="newpassword"
                    name="newpassword"
                    type="password"
                    {...register("newpassword", {
                      required: false,
                    })}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="py-3 bg-gray-50 text-left ">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="inline-flex  justify-center py-2 px-4 mr-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] focus:outline-none transition duration-150 ease-in-out"
                  >
                    Save
                  </button>
                </span>
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    onClick={() => {
                      props.setPage("orders");
                      props.setPageTitle("Orders");
                    }}
                    type="button"
                    className="inline-flex items-center py-2 px-4 border border-gray-300 text-xs leading-5  rounded-md text-gray bg-white hover:text-gray-500 focus:outline-none focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUserPassword;
