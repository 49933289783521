import React from 'react';
import WebSocketClient from './socket/WebSocketClient';

import Main from './pages/Main';

function App() {
 
  return (
    <WebSocketClient>
      <Main />
    </WebSocketClient>
  );
}

export default App;
