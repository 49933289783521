import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import CategoryForm from './CategoryForm';
import { API } from "./AppConfig";


const OrderForm = props => {
  const catRef = useRef();
  const statusList = useRef();
  const uploadFile = useRef();
  // eslint-disable-next-line no-unused-vars
  const [wait, setWait] = useState('Add');
  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([
    {
      _id: '',
      name: 'Click Add to add a category'
    }
  ]);
  const [showModal, setShowModal] = useState(false);
  const [saveMode, setSaveMode] = useState('add');
  const [addedCategory, setAddedCategory] = useState(null);
  const [percent, setPercent] = useState(0);
  const [defaultCategory, setDefaultCategory] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [defaultData, setDefaultData] = useState({
    id: '',
    name: '',
    type: 'Unbounces',
    active: true
  });

  useEffect(() => {
    const getCategories = async () => {
      const response = await fetch(`${API}/categories`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props.user.token
        },
        body: JSON.stringify({
          sort: 'name:1',
          start: 0,
          limit: 0,
          type: ['Unbounces'],
          user: props.user.id,
          search: '',
          active: true
        }),
        redirect: 'follow'
      });

      if (!response.ok) {
        const errorResData = await response.json();
        console.log(errorResData);
      } else {
        const data = await response.json();
        if (data && data.length > 0) {
          let added = data[0]._id;
          // console.log(added);
          if (addedCategory !== null) {
            added = data.find(c => c.name === addedCategory);
          }
          setDefaultCategory(added?added._id:"");
          setCategories(data);
        }
      }
    };

    getCategories();
  }, [props.user, addedCategory]);

  const onChangeHandler = () => {
    var file = uploadFile.current.files[0];

    // Allowed types
    var mime_types = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    // Validate MIME type
    if (mime_types.indexOf(file.type) === -1) {
      alert('Error : Incorrect file type');
      return;
    }

    // Max 2 Mb allowed
    if (file.size > 1000 * 1024 * 1024) {
      alert('Error : Exceeded size 1GB');
      return;
    }


    // Validation is successful
    // This is the name of the file
    // alert('You have chosen the file ' + file.name);

    var data = new FormData();

    var request = new XMLHttpRequest();

    // File selected by the user
    // In case of multiple files append each of them
    // data.append('file', document.querySelector('#upload-file').files[0]);
    data.append('file',file);
    data.append('email', props.user.email);
    data.append('user', props.user.id);
    data.append('category', catRef.current.value);

    // AJAX request finished
    request.addEventListener('load', function(e) {
      // request.response will hold the response from the server
      // console.log(request.response);
    });

    // Upload progress on request.upload
    request.upload.addEventListener('progress', function(e) {
      var p = (e.loaded / e.total) * 100;

      setPercent(p);
      // Percentage of upload completed
      console.log(p);
    });
    request.onreadystatechange = function() {
      // uploadFile.current.value = '';
      if (this.readyState === 4 && this.status === 200) {
        props.setShowModal(false);
      }
    };

    // If server is sending a JSON response then set JSON response type
    request.responseType = 'json';

    // Send POST request to the server side script
    request.open('put', `${API}/orders/add`);
    request.setRequestHeader('Authorization', 'Bearer ' + props.user.token);
    request.send(data);
    uploadFile.current.value = '';
  };

  return (
    <div>
      {!showModal && (
        <form>
          <div>
            <div className='mt-3 pt-2 border-t border-orange-600'>
              <div className='mt-1 grid grid-cols-1 row-gap-2 col-gap-4 sm:grid-cols-4'>
                <div className='sm:col-span-5'>
                  <div className='flex flex-row justify-between items-center'>
                  <label
                    htmlFor='type'
                    className='block text-sm font-medium leading-5 text-gray-700'>
                    Choose a category for your unbounce order
                  </label>
                  <button
                      onClick={() => {
                        setSaveMode('add');
                        setShowModal(true);
                      }}
                      className='-ml-px relative inline-flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-r-md text-pink-700 hover:text-orange-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
                      <span className='ml-2 '>New Category</span>
                    </button>
                    </div>
                  <div className='mt-1 flex rounded-md shadow-sm'>
                    <select
                      onChange={e => {
                        setDefaultCategory(e.target.value);
                      }}
                      value={defaultCategory || '-'}
                      ref={catRef}
                      name='category'
                      id='category'
                      className='form-select block w-full px-3 py-2 border border-r-0 border-gray-300 rounded-l-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue 
                  focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
                      {categories.length > 0 &&
                        categories.map((c,i)=> (
                          <option key={i+c._id} value={c._id}>
                            {c.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div>
                  <input
                    ref={uploadFile}
                    className='hidden'
                    id='upload-file'
                    accept='text/csv,application/vnd.ms-excel'
                    name='bulk'
                    type='file'
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {bounceStatus.length > 0 && <BounceStatus status={bounceStatus} />} */}
          <ul ref={statusList}></ul>

          {percent > 0 && (
            <div
              style={{ height: 100, display: "flex", flexDirection:"column", justifyContent: "center" }}
            >
              <CircularProgressbar
                value={percent.toFixed(2)}
                text={`${percent.toFixed(2)}%`}
                styles={buildStyles({
                  pathColor: "#6875F5",
                })}
              />
              <small style={{alignSelf:"center", marginTop:10}}>Uploading...</small>
            </div>
          )}

          <div className='mt-4 border-t border-gray-200 pt-5'>
            <div className='flex justify-end'>
              {/* <span className='inline-flex rounded-md shadow-sm'>
                <a
                  href='http://localhost:9000/download-format/recipient'
                  className='py-2 px-4 border text-center border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out'>
                  Download Upload Format
                </a>
              </span> */}
              <span className='ml-5 inline-flex rounded-md shadow-sm'>
                <button
                  onClick={() => {
                    props.setShowModal(false);
                  }}
                  type='button'
                  className='py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out'>
                  Cancel
                </button>
              </span>
              <span className='ml-3 inline-flex rounded-md shadow-sm'>
                <button
                  onClick={() => {
                    console.log("global.ws",global.ws)
                    if (!global.ws) {
                      toast("Cannot create new order. No connection");
                      window.location.reload();
                      return;
                    }
                    uploadFile.current.click();
                  }}
                  type='button'
                  className='inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] focus:outline-none transition duration-150 ease-in-out'>
                  {/* Upload your file (csv/xls/xlsx) */}
                  {wait}
                </button>
              </span>
            </div>
          </div>
        </form>
      )}
      {showModal && (
        <CategoryForm
          saveMode={saveMode}
          setShowModal={setShowModal}
          user={props.user}
          data={defaultData}
          addedCategory={setAddedCategory}
          hideType={true}
        />
      )}
       <ToastContainer />
    </div>
  );
};

export default OrderForm;
