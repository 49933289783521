/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';

const InfoIcon = ({ details }) => {
  return (
    <div>
      <a data-tip={details} data-for='main' href='#'>
        <svg
          className='inline mx-1 -mt-1'
          width='12px'
          height='12px'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'>
          <path
            fill='#6875f5'
            d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z'
          />
        </svg>
      </a>
      <ReactTooltip
        id='main'
        place={'left'}
        type={'info'}
        effect={'float'}
        multiline={true}
      />
    </div>
  );
};

export default InfoIcon;
