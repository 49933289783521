import React,{useState} from 'react'
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { useEffect } from 'react';

const Landing = ({ setIsLoggedIn, setOpenProfile }) => {
    const [open, setOpen] = useState(true);
    const [openRegister, setOpenRegister] = useState(false);
    const [openForgot, setOpenForgot] = useState(false);
    useEffect(()=>{
        if(open){
            setOpenRegister(false);
            setOpenForgot(false);
        }
        if(openRegister){
            setOpen(false);
            setOpenForgot(false);
        }
        if(openForgot){
            setOpenRegister(false);
            setOpen(false);
        }

    },[open,openRegister,openForgot])
  return (
    <div className='h-screen bg-white'>
    {open && <Login
    setOpen={setOpen}
    setIsLoggedIn={setIsLoggedIn}
    setOpenProfile={setOpenProfile}
    setOpenRegister={setOpenRegister}
    setOpenForgot={setOpenForgot}
  />}
  {openRegister && <Register 
    setIsLoggedIn={setIsLoggedIn} 
    setOpenRegister={setOpenRegister}
    setOpen={setOpen}
    /> }
    {openForgot && <ForgotPassword
    setOpenForgot={setOpenForgot}
    setOpen={setOpen}
    />}
    </div>
  )
}

export default Landing