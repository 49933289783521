import React from "react";

const TableButton = ({ title, onClick, icon = "" }) => {
  return (
    <span className="inline-flex rounded-md shadow-sm mt-1 ml-2">
      <button
        onClick={onClick}
        type="button"
        style={{fontFamily:"Inter"}}
        className="inline-flex items-center p-2 border border-gray-300 text-md leading-2 rounded-md text-white bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] hover:text-gray-500 focus:outline-none focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
      >
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          className="h-5 w-5 text-gray mr-1"
        >
          {icon === "add" && (
            <path
              fill="#fff"
              className="heroico n-ui"
              d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
            />
          )}

          {icon === "import" && (
            <path
              fill="#6875F5"
              d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"
            />
          )}

          {icon === "refresh" && (
            <path
              fill="#6875F5"
              d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z"
            />
          )}
        </svg>
        {title}
      </button>
    </span>
  );
};

export default TableButton;
