/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Loader from "./Loader";
import InfoIcon from "./InfoIcon";

const Cell = (data) => {
  return (
    <td className="px-1 whitespace-no-wrap border-b border-gray-200">
      <div className={`flex items-center justify-${data.align}`}>
        {data.avatar && (
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1532910404247-7ee9488d7292?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
        )}

        {data.checkbox && (
          <div className="flex-shrink-0 px-1">
            {data.checkbox ? (
              <img className="h-4 w-4" src="images/icons/checked.svg" alt="" />
            ) : (
              <img
                className="h-4 w-4"
                src="images/icons/unchecked.svg"
                alt=""
              />
            )}
          </div>
        )}

        {data.chart && (
          <div className="w-10 font-black">
            <CircularProgressbar
              value={data.chart.percentage}
              text={`${data.chart.percentage}`}
              styles={buildStyles({
                pathColor: data.chart.color,
                // trailColor: '#eee'
                // strokeLinecap: 'butt'
              })}
            />
          </div>
        )}

        {data.badge ? (
          <div
            style={{
              display: "flex",
              backgroundColor: data.badge.bg,
              color: data.badge.color,
              width: 95,
              height: 28,
              borderRadius: 16,
              justifyContent: "space-around",
              alignItems: "center",
              padding: 10,
            }}
          >
            <span
              style={{
                backgroundColor: data.badge.dot,
                width: 6,
                height: 6,
                left: 1,
                top: 1,
                borderRadius: 16,
              }}
            ></span>{" "}
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 18,
                /* identical to box height, or 150% */
                textAlign: "center",
                color: data.badge.color,
              }}
            >
              {data.title}
            </span>{" "}
            {data.info ? <InfoIcon details={data.details} /> : ""}
          </div>
        ) : (
          data.title && (
            <div className="py-1">
              {data.title && (
                <div id={data._id} className="text-xs text-gray-900">
                  {data.title}
                  {data.info ? <InfoIcon /> : ""}
                  {data.loader ? <Loader /> : ""}
                </div>
              )}
              {data.subtitle !== undefined && (
                <div className="text-xs text-gray-500">{data.subtitle}</div>
              )}
            </div>
          )
        )}

        {data.btn && (
          <div className="flex flex-row justify-between">
            {data.btn &&
              data.btn.map((b) =>
                b.name === "download" ? (
                  <a key={"btn" + b.name} id={b.id} href={b.path}>
                    <svg
                      className="mt-2 mx-1"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.285767 16.1053H18.2858V18H0.285767V16.1053ZM10.2858 10.5916L16.3658 4.83158L17.7658 6.17684L9.28577 14.2105L0.805767 6.17684L2.20577 4.83158L8.28577 10.5726V0H10.2858V10.5916Z"
                        fill="#667085"
                      />
                    </svg>
                  </a>
                ) : (
                  <button
                    id={b.id}
                    type="button"
                    title={b.name.toUpperCase()}
                    key={"btn" + b.name}
                    className={"m-1 outline-none"}
                    onClick={() => {
                      b.onClick();
                    }}
                  >
                    {b.name === "edit" && (
                      <svg className="h-4, w-4" viewBox="0 0 23 23">
                        <path
                          fill="#6875F5"
                          className="heroicon-ui"
                          d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z"
                        />
                      </svg>
                    )}
                    {b.name === "resume" && (
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.14137 6.15498C8.30827 5.69354 7.28577 6.29608 7.28577 7.24847V12.7516C7.28577 13.704 8.30827 14.3065 9.14137 13.8451L14.8992 10.6561C15.1378 10.524 15.2858 10.2727 15.2858 10C15.2858 9.7273 15.1378 9.4761 14.8992 9.3439L9.14137 6.15498ZM10.2858 0C4.76292 0 0.285767 4.47715 0.285767 10C0.285767 15.5228 4.76292 20 10.2858 20C15.8086 20 20.2858 15.5228 20.2858 10C20.2858 4.47715 15.8086 0 10.2858 0ZM1.78577 10C1.78577 5.30558 5.59135 1.5 10.2858 1.5C14.9802 1.5 18.7858 5.30558 18.7858 10C18.7858 14.6944 14.9802 18.5 10.2858 18.5C5.59135 18.5 1.78577 14.6944 1.78577 10Z"
                          fill="#667085"
                        />
                      </svg>
                    )}
                    {b.name === "pause" && (
                      <svg width="23" height="22" viewBox="0 0 21 21">
                        <path
                          fill="gray"
                          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM7 6h2v8H7V6zm4 0h2v8h-2V6z"
                        />
                      </svg>
                    )}
                    {b.name === "remove" && (
                      <svg width="25" height="24" viewBox="0 0 21 21">
                        <path
                          fill="red"
                          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z"
                        />
                      </svg>
                    )}
                    {b.name === "cancel" && (
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.2858 22C17.7858 22 22.2858 17.5 22.2858 12C22.2858 6.5 17.7858 2 12.2858 2C6.78577 2 2.28577 6.5 2.28577 12C2.28577 17.5 6.78577 22 12.2858 22Z"
                          stroke="#667085"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.45575 14.8299L15.1157 9.16992"
                          stroke="#667085"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.1157 14.8299L9.45575 9.16992"
                          stroke="#667085"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                    {b.name === "send" && (
                      <svg
                        fill="currentColor"
                        className="mt-1 h-3, w-3"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="#6875F5"
                          d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z"
                        />
                      </svg>
                    )}

                    {b.name === "unsubscribe" && (
                      <svg
                        fill="currentColor"
                        className="h-4, w-4"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="red"
                          d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"
                        />
                      </svg>
                    )}
                  </button>
                )
              )}
          </div>
        )}
      </div>
    </td>
  );
};

export default Cell;
