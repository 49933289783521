/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
const Column = ({
  slno,
  checkbox,
  title,
  ord = 'ASC',
  col = '',
  align = 'start',
  sort,
  setSort,
  setOrd
}) => {
  return (
    <th
      className={`sticky top-0 px-1 py-3
       border-y border-gray-200 text-left text-xs leading-4 font-sans font-medium text-gray-200 bg-white uppercase tracking-wider`}>
      <a
        href='#'
        onClick={() => {
          if(title === "#"){
            return;
          }
          setOrd(s => (s === 'ASC' ? 'DESC' : 'ASC'));
          setSort(col);
        }}
        className={'flex justify-' + align}>
        {checkbox ? (
          <div className='flex-shrink-0 px-1'>
            {checkbox ? (
              <img className='h-4 w-4' src='images/icons/checked.svg' alt='' />
            ) : (
              <img
                className='h-4 w-4'
                src='images/icons/unchecked.svg'
                alt=''
              />
            )}
          </div>
        ) : (
          <span className={'cursor-pointer, text-gray-900 text-center'} style={{fontFamily:"Inter"}}>
            {title}
          </span>
        )}
        {col === sort && (
          <span className={'mx-1'}>
            {ord === 'ASC' ? (
              <svg className='h-4 w-4 text-gray-200' viewBox='0 0 24 24'>
                <path
                  stroke='currentColor'
                  className='heroicon-ui'
                  d='M11 18.59V3a1 1 0 0 1 2 0v15.59l5.3-5.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-7-7a1 1 0 0 1 1.4-1.42l5.3 5.3z'
                />
              </svg>
            ) : (
              <svg className='h-4 w-4 text-gray-200' viewBox='0 0 24 24'>
                <path
                  stroke='currentColor'
                  className='heroicon-ui'
                  d='M13 5.41V21a1 1 0 0 1-2 0V5.41l-5.3 5.3a1 1 0 1 1-1.4-1.42l7-7a1 1 0 0 1 1.4 0l7 7a1 1 0 1 1-1.4 1.42L13 5.4z'
                />
              </svg>
            )}
          </span>
        )}
      </a>
    </th>
  );
};

export default Column;
