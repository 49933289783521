/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { API } from "./AppConfig";
import Login from "./Login";
import Orders from "./Orders";
import Landing from "./Landing";
import UserSettings from "./UserSettings";
import ChangeUserPassword from "./ChangeUserPassword";
import SocketContext from "../context";
import Modal from "../components/Modal";
import Search from "../components/Search";
import TableButton from "../components/TableButton";
import { vh, vw } from "./util";

const Main = () => {
  const socket = useContext(SocketContext);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("user") !== null ? true : false
  );
  const [pageTitle, setPageTitle] = useState("Orders");
  const [openProfile, setOpenProfile] = useState(false);
  const [openVm, setOpenVm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState("orders");
  const [plans, setPlans] = useState(null);
  const [paylink, setPaylink] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState("");
  const [start, setStart] = useState(0);

  const userLogout = () => {
    if (localStorage.getItem("user") !== null) {
      localStorage.removeItem("user");
      setIsLoggedIn(false);
      setOpenProfile(!openProfile);
      // socket.emit('logout', { user: user.id });
    }
  };

  // useEffect(() => {
  //   const getPlans = async () => {
  //     const response = await fetch(`${API}/plans/get`, {
  //       method: "POST",
  //       // headers: {
  //       //   Authorization: "Bearer " + user.token,
  //       // },
  //       body: JSON.stringify({
  //         skip: 0,
  //         limit: 10,
  //       }),
  //     });

  //     if (!response.ok) {
  //       const errorResData = await response.json();
  //       console.log(errorResData);
  //     } else {
  //       const result = await response.json();
  //       setPlans(result.data);
  //     }
  //   };
  //   if (showModal) {
  //     getPlans();
  //   }
  // }, [showModal]);

  // console.log("plans", plans);

  return (
    <div className="h-screen bg-white">
      <div className="flex flex-row justify-between items-center mx-12 py-4">
        <div className="flex flex-row justify-between items-center">
          <div>
            <img
              style={{ width: vw(48), height: vh(29) }}
              src="/images/ve-logo.png"
              alt="ve-logo"
            />
          </div>
          <div className="mx-6">
            <div
              style={{ fontSize: vh(22), fontFamily: "sans-serif",fontFamily:"Inter" }}
              className="font-medium text-gray-700 focus:text-black"
            >
              {pageTitle}
            </div>
            <div
              style={{ fontSize: vh(16), fontFamily: "sans-serif",fontFamily:"Inter" }}
              className="font-medium text-gray-400 focus:text-black"
            >
              {page==="orders"?"You can add and view your orders here.":""}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <Search setStart={setStart} search={search} setSearch={setSearch} />
          <TableButton
            key="Add New"
            title="Add New Order"
            icon="add"
            onClick={() => setShowModal(true)}
          />
          <div className="ml-3 relative">
            <div>
              <button
                onClick={() => {
                  setOpenProfile(!openProfile);
                }}
                className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
              >
                {!isLoggedIn ? (
                  <svg
                    className="h-10 w-10 rounded-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.26 11.74L10 14H8v2H6v2l-2 2H0v-4l8.26-8.26a6 6 0 1 1 4 4zm4.86-4.62A3 3 0 0 0 15 2a3 3 0 0 0-2.12.88l4.24 4.24z" />
                  </svg>
                ) : (
                  <svg
                    className="h-10 w-10 rounded-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path>
                  </svg>
                )}
              </button>
            </div>

            <div
              style={{ width: !isLoggedIn ? 300 : "12rem" }}
              className={
                openProfile
                  ? "origin-top-right absolute right-0 mt-2 w-48 rounded-md "
                  : "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg hidden"
              }
            >
              {!isLoggedIn && (
                <Login
                  setOpenProfile={setOpenProfile}
                  setIsLoggedIn={setIsLoggedIn}
                />
              )}
              {isLoggedIn && (
                <div
                  style={{ zIndex: 9999 }}
                  className="py-1 rounded-md bg-white shadow-xs absolute"
                >
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <div className={"text-xs"}>Signed in as:</div>
                    <div className={"text-gray-900 font-bold"}>
                      {user && user.email ? user.email : ""}
                    </div>
                  </a>
                  <a
                    onClick={() => {
                      setPage("profile");
                      setOpenProfile(false);
                      setPageTitle("Profile");
                    }}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Profile
                  </a>
                  <a
                    onClick={() => {
                      setPage("changepassword");
                      setOpenProfile(false);
                      setPageTitle("Change Password");
                    }}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Change Password
                  </a>
                  <a
                    onClick={userLogout}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign out
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isLoggedIn ? (
        <div>
          <Landing
            setOpenProfile={setOpenProfile}
            setIsLoggedIn={setIsLoggedIn}
          />
        </div>
      ) : (
        <div>
          {page === "orders" && (
          <Orders
            user={user}
            setPage={setPage}
            setIsLoggedIn={setIsLoggedIn}
            search={search}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          )}
          {page === "profile" && (
            <UserSettings
              user={user}
              setPage={setPage}
              setPageTitle={setPageTitle}
            />
          )}
          {page === "changepassword" && (
            <ChangeUserPassword
              user={user}
              setPage={setPage}
              setPageTitle={setPageTitle}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Main;
