import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SocketContext from "../context";
import { WSURL } from "../pages/AppConfig";

const WebSocketClient = ({ children}) => {
  const [websocket, setWebsocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectInterval = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const connectWebSocket = () => {
    const url = WSURL + "?user=" + global.clientID;
    const ws = new WebSocket(url);

    ws.onopen = () => {
      setIsConnected(true);
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
      ws.send(JSON.stringify({action:"client:connect"}))
      // console.log("WebSocket connected");
    };

    ws.onclose = () => {
      setIsConnected(false);
      scheduleReconnect();
    };

    ws.onerror = (error) => {
      if (!reconnectInterval.current) {
        console.error("WebSocket error:", error);
      }
    };

    // ws.onmessage = (event) => {
    //   console.log("event",event)
    //   console.log("Received websocket message");      
    // };
    global.ws = ws
    // setSocket(ws);
    setWebsocket(ws);
  };

  const scheduleReconnect = () => {
    if (!reconnectInterval.current) {
      console.log("WebSocket disconnected");
      reconnectInterval.current = setInterval(connectWebSocket, 5000); // Retry connection every 5 seconds
    }
  };

  const disconnectWebSocket = () => {
    if (websocket) {
      websocket.close();
      setWebsocket(null);
      setIsConnected(false);
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
    }
  };

  useEffect(() => {
    if (user) {
      global.clientID = user.id;
      connectWebSocket();
    }

    return () => {
      disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      console.log("Websocket Connected");
    } else {
      console.log("Websocket Not connected");
    }
  }, [isConnected]);

  return (
    <SocketContext.Provider value={{ socket: websocket }}>
      {children}
      <ToastContainer />
    </SocketContext.Provider>
  );
};

export default WebSocketClient;
