import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ pages, limit, setStart }) => {
  const handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);

    setStart(offset);
  };
  return (
    <div className='sticky bottom-0 bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg'>
      <div className='justify-center mx-auto'>
        <ReactPaginate
          previousLabel={'<'}
          previousClassName={
            'relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-base leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150'
          }
          previousLinkClassName={'outline-none'}
          nextLabel={'>'}
          nextClassName={
            '-ml-px relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-base leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150'
          }
          nextLinkClassName={'outline-none'}
          breakLabel={'...'}
          breakClassName={
            '-ml-px relative inline-flex items-center px-4 py-1 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700'
          }
          pageClassName={
            '-ml-px relative inline-flex items-center px-4 py-1 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'
          }
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={
            'hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'
          }
          subContainerClassName={'pages pagination'}
          activeClassName={'active bg-gray-400'}
          activeLinkClassName={'outline-none'}
        />
      </div>
    </div>
  );
};

export default Pagination;
