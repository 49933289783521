import React from 'react';

const InputError = ({ error }) => {
  // console.log(error.error.message);
  return (
    <p className='block text-red-500 text-xs italic mt-1'>{error.message}</p>
  );
};

export default InputError;
