/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { renderToString } from "react-dom/server";
import moment from "moment";
// import SocketContext from '../context';
import Cell from "../components/Cell";
import Column from "../components/Column";
import Pagination from "../components/Pagination";
import Limit from "../components/Limit";
import Search from "../components/Search";
import Modal from "../components/Modal";
import OrderForm from "./OrderForm";
import { ToastContainer, toast } from "react-toastify";
import SocketContext from "../context";
import { API } from "./AppConfig";

const Orders = ({ user, setIsLoggedIn,search,showModal,setShowModal }) => {
  const socket = useContext(SocketContext);
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([]);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("success");
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [pages, setPages] = useState(1);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(
    user && user.settings && user.settings.listlimit ? 10 : 0
  );
  // const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  // const [showModal, setShowModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [saveMode, setSaveMode] = useState("add");
  const [defaultData, setDefaultData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    category: "",
    active: true,
  });
  const [sort, setSort] = useState("_id");
  const [ord, setOrd] = useState("DESC");

  useEffect(() => {
    if (global.ws) {
      console.log("in socket");
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      global.ws.onmessage = async ({ data }) => {
        let message = JSON.parse(data);

        if (
          message.action &&
          message.action === "order:status" &&
          !message.err
        ) {
          if (message.action === "order:status") {
            if (message.err) {
              console.log("order:status", message.err);
              return;
            }
            if (message && message.order && message.order === "") {
              console.log("order:status order not found");
              return;
            }
            const order = JSON.parse(message.order);
            if (!order) {
              console.log("order:status order not found");
              return;
            }
            console.log("realtime order", order);

            let msg = `Order ${order._id} Cancelled`;
            if (order.status === "Processing") {
              msg = `Order ${order._id} Started`;
            }
            if (order.status === "Paused") {
              msg = `Order ${order._id} Paused`;
            }
            if (order.status === "In-Queue") {
              msg = `Order ${order._id} Queued`;
            }
            if (order.status === "Completed") {
              msg = `Order ${order._id} Completed`;
            }

            // toast.success(`${msg}`);

            var orderRow = document.getElementById(order._id);
            if (orderRow) {
              const col = orderRow.querySelector("td");
              await sleep(500);
              orderRow.innerHTML = renderToString(
                <tr>
                  <td
                    className="text-center text-sm font-medium p-2"
                    colSpan={15}
                  >
                    {order.status}
                  </td>
                </tr>
              );

              var updatedRow = document.getElementById(order._id);
              updatedRow.className = "";
              updatedRow.classList.add("blink-" + order.status.toLowerCase());
              await sleep(2000);
              console.log("row index", orderRow.rowIndex, start);
              const rowslno = orderRow.rowIndex + start;
              orderRow.innerHTML = renderToString(
                <TableRow item={order} slno={rowslno} />
              );
              orderRow.className = "";
              const pause = document.getElementById("pause-" + order._id);
              const resume = document.getElementById("resume-" + order._id);
              const cancel = document.getElementById("cancel-" + order._id);
              const download = document.getElementById("download-" + order._id);
              pause &&
                pause.addEventListener("click", async () => {
                  await changeStatus(order._id, "Paused", "pause");
                });
              resume &&
                resume.addEventListener("click", async () => {
                  await changeStatus(order._id, "Processing");
                });
              cancel &&
                cancel.addEventListener("click", async () => {
                  await changeStatus(order._id, "Cancelled", "cancel");
                });
              download && download.addEventListener("click", async () => {});
            }
          }
          // setRefresh(true);
        }
      };
    } else {
      console.log("websocket not found");
    }
  }, [global.ws]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const response = await fetch(`${API}/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },

          body: JSON.stringify({
            sort: sort + ":" + ord,
            start: start,
            limit: localStorage.getItem("listlimit")
              ? parseInt(localStorage.getItem("listlimit"))
              : limit,
            user: user.id,
            search: search,
          }),
          redirect: "follow",
        });

        if (!response.ok) {
          const errorResData = await response.json();
          console.log(errorResData);
          setType("danger");
          localStorage.removeItem("user");
          setIsLoggedIn(false);
          setMsg(errorResData);
          setOpen(true);
        } else {
          const data = await response.json();
          if (data && data.orders && data.orders.length > 0) {
            setList(data.orders);
            setPages(Math.ceil(data.count / limit));
          } else {
            setList([]);
            setPages(1);
          }
        }
        setRefresh(false);
      } catch (err) {
        setType("danger");
        setMsg("Connection Failed!");
        setOpen(true);
        console.log(err);
      }
    };

    if (user && !showModal) {
      getOrders();
    }
  }, [
    user,
    refresh,
    limit,
    start,
    search,
    sort,
    ord,
    showModal,
    setIsLoggedIn,
  ]);

  const changeStatus = async (id, status, action = "start") => {
    try {
      const response = await fetch(`${API}/orders/change-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },

        body: JSON.stringify({
          id,
          status,
          action,
        }),
        redirect: "follow",
      });

      if (!response.ok) {
        const { error } = await response.json();

        setType("danger");
        setMsg(error);
        setOpen(true);
      } else {
        const { success, order } = await response.json();
        if (success) {
          return;
        } else {
          setType("danger");
          setMsg("Something went wrong.");
          setOpen(true);
        }
      }
      // setRefresh(false);
    } catch (err) {
      setType("danger");
      setMsg("Change Status Failed!");
      setOpen(true);
      console.log(err);
    }
  };

  const refreshOrder = async () => {
    try {
      const response = await fetch(`${API}/orders/refresh`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        redirect: "follow",
      });
      if(response.ok){
        setRefresh(!refresh);
        toast("Orders refreshed");
      }
    } catch (err) {
      toast("Something went wrong while refreshing");
    }
  };

  let slno = start + 1;

  const TableRow = ({ item, slno }) => {
    const badge = {
      completed:{
        bg:"#ECFDF3",
        color:"#037847",
        dot:"#14BA6D",
        chart:"#037847"
      },
      canceled:{
        bg:"#CCC",
        color:"rgba(0,0,0,0.5)",
        dot:"rgba(0,0,0,0.5)",
        chart:"#000"
      },
      paused:{
        bg:"#FFD166",
        color:"#000",
        dot:"#000",
        chart:"#FFD166"
      },
      processing:{
        bg:"#0077B6",
        color:"#fff",
        dot:"#fff",
        chart:"#0077B6"
      },
      inqueue:{
        bg:"#A05195",
        color:"#fff",
        dot:"#fff",
        chart:"#A05195",
      },
      scheduled:{
        bg:"#FF9A8B",
        color:"#fff",
        dot:"#fff",
        chart:"#FF9A8B",
      }
    }
    let color = "#FF5733";
    const user = item.user;
    const btn = [];
    let badgecolor = badge.canceled;

    const download = {
      id: "download-" + item._id,
      path: `${API}/download/${user._id}:${item._id}`,
      name: "download",
      // filename: `${item._id}.zip`
    };

    const cancel = {
      id: "cancel-" + item._id,
      name: "cancel",
      onClick: async () => {
        await changeStatus(item._id, "Cancelled", "cancel");
      },
    };
    const pause = {
      id: "pause-" + item._id,
      name: "pause",
      onClick: async () => {
        await changeStatus(item._id, "Paused", "pause");
      },
    };
    const start = {
      id: "resume-" + item._id,
      name: "resume",
      onClick: async () => {
        await changeStatus(item._id, "Processing");
      },
    };

    if (item.status === "Processing") {
      badgecolor = badge.processing
      btn.push(cancel, pause);
    } else if (item.status === "Scheduled") {
      badgecolor = badge.scheduled;
      btn.push(cancel);
    } else if (item.status === "Completed") {
      // console.log("item report",item.report,item._id,item.createdAt,item.category.name)
      badgecolor = badge.completed;
      if (item.report === true) {
        btn.push(download);
      }
    } else if (item.status === "Paused") {
      badgecolor = badge.paused;
      btn.push(cancel, start, download);
    }else if (item.status === "In-Queue") {
      badgecolor = badge.inqueue;
      btn.push(cancel);
    }

    const duration =
      moment(item.updatedAt).diff(moment(item.createdAt), "minutes") + "m";
    console.log("duration",duration,item.report,item.updatedAt,item.createdAt)
    // console.log(moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("hh:mm:ss"))
    // console.log("ID", item._id, item.updatedAt, item.createdAt, duration);
    const verified =
      item.valid +
      item.invalid +
      item.catchall +
      (item.unknown ? item.unknown : 0);
    let percentage = 0;
    if (verified) {
      percentage = ((verified / item.total) * 100).toFixed(2);
    } else {
      percentage = ((0 / item.total) * 100).toFixed(2);
    }

    percentage = isNaN(percentage) ? parseFloat(0.0).toFixed(2) : percentage;

    return (
      <tr id={item._id}>
        {/* <Cell align='center' checkbox={true} /> */}
        <Cell align="center" title={slno} />
        <Cell
          align="center"
          title={moment(item.orderdate).format("MM-DD-YYYY HH:mm")}
        />
        <Cell align="start" title={item.category ? item.category.name : "-"} />

        <Cell
          _id={item._id + "_valid"}
          align="center"
          title={item.valid ? item.valid.toString() : "0"}
        />
        <Cell
          _id={item._id + "_catchall"}
          align="center"
          title={item.catchall ? item.catchall.toString() : "0"}
        />
        <Cell
          _id={item._id + "_invalid"}
          align="center"
          title={item.invalid ? item.invalid.toString() : "0"}
        />
        <Cell
          _id={item._id + "_unknown"}
          align="center"
          title={item.unknown ? item.unknown.toString() : "0"}
        />
        <Cell
          _id={item._id + "_total"}
          align="center"
          title={
            verified.toString() +
            "/" +
            (item.total ? item.total.toString() : "0")
          }
        />
        <Cell
          align="center"
          chart={{
            percentage: percentage ? percentage.toString() : "0",
            color:badgecolor.chart,
          }}
        />

        <Cell
          align="center"
          title={item.status}
          badge={badgecolor}
        />
        <Cell align="center" title={duration} />
        <Cell item={item} align="center" col={item._id} btn={btn} />
      </tr>
    );
  };

  const listLimit = localStorage.getItem("listlimit")
    ? parseInt(localStorage.getItem("listlimit"))
    : limit;
  return (
    <div className="h-full min-h-screen rounded-lg">
      <div className="mx-auto p-2">
        {/* <div className="flex flex-row justify-between bg-white py-1 px-1 rounded-t-lg">
          <Limit
            btn={[
              {
                title: "Create New Order",
                onClick: () => {
                  if (!global.ws) {
                    toast("Cannot create new order. No connection");
                    window.location.reload();
                    return;
                  }
                  setShowModal(true);
                },
                icon: "add",
              },
              {
                title: "Refresh",
                onClick: async() => {
                 await refreshOrder();
                },
                icon: "refresh"
              },
            ]}
            limit={listLimit}
            setStart={setStart}
            setLimit={setLimit}
          />

          <Search setStart={setStart} search={search} setSearch={setSearch} />
        </div> */}
        <table className="min-w-full  ">
          <thead>
            <tr>
              <Column title="#" align="center" />
              <Column
                title="Order Date"
                col="orderdate"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column
                title="Category"
                col="category"
                ord={ord}
                sort={sort}
                align="start"
                setOrd={setOrd}
                setSort={setSort}
              />

              <Column
                title="Valid"
                col="valid"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column
                title="Catch All"
                col="catchall"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column
                title="Invalid"
                col="invalid"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column
                title="Unknown"
                col="unknown"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column
                title="Verified/Total"
                col="total"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column title="Completed %" align="center" />
              <Column
                title="Status"
                col="status"
                ord={ord}
                sort={sort}
                align="center"
                setOrd={setOrd}
                setSort={setSort}
              />
              <Column title="Duration" align="center" />
              <Column title="Action" align="center" />
            </tr>
          </thead>
          <tbody className="bg-white">
            {list &&
              list.length > 0 &&
              list.map((item, idx) => {
                // console.log(item, "Order");
                return <TableRow key={item._id} item={item} slno={slno++} />;
              })}
          </tbody>
        </table>
        {list && list.length === 0 && (
          <div className="px-6 py-4 text-center bg-white whitespace-no-wrap rounded-b-lg">
            No data found!
          </div>
        )}
        {list && list.length > 0 && (
          <Pagination pages={pages} limit={limit} setStart={setStart} />
        )}
      </div>

      {showModal && (
        <Modal
          title={saveMode === "add" ? "Add Order" : "Edit Order"}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <OrderForm
            setDefaultData={setDefaultData}
            categories={categories}
            saveMode={saveMode}
            setShowModal={setShowModal}
            user={user}
            data={defaultData}
          />
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
};

export default Orders;
