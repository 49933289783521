import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "./AppConfig";

const ForgotPassword = ({ setOpenForgot,setOpen }) => {
  const [message, setMessage] = useState();
  const [reset, setReset] = useState(false);

  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const resetPassword = async (data) => {
    console.log(data)
    const response = await fetch(`${API}/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resetcode: data.resetcode, password: data.password, confirmpassword: data.confirmpassword }),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      setMessage("Couldn't reset password");
    } else {
      const data = await response.json();
      if (data.success) {
        setMessage("Password reset successful");
        setReset(true);
      } else {
        setMessage("Couldn't send reset password");
      }
    }
  };

  const sendResetCode = async (data) => {
    const response = await fetch(`${API}/forgot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: data.email }),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      setMessage("Couldn't send reset password code");
    } else {
      const data = await response.json();
      data.success
        ? setMessage("Reset password code sent to your email")
        : setMessage("Couldn't send reset password code");
    }
  };

  return reset ? (
    <div className="fixed flex justify-center items-center z-50 bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] shadow-lg w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen">
    <div className="max-w-sm w-full">
      <form
        onSubmit={handleSubmit(resetPassword)}
        className="bg-white shadow-md rounded px-8 pb-8 pt-4 mb-4"
      >
        <div id="contact-success">
        {message !== undefined && (
          <div className="text-blue-700 px-4 my-3" role="alert">
            <p className="font-small">{message}</p>
            <p id="invalid-msg" className="text-sm"></p>
          </div>
        )}
        </div>
        <div>
          <label
            className="block text-orange-500 text-sm font-bold mb-2"
            htmlFor="resetcode"
          >
            Enter reset code
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="resetcode"
            type="text"
            placeholder="Enter reset code"
            name="resetcode"
            {...register("resetcode",{
              required: "Required",
            })}
          />
           <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.resetcode ? errors.resetcode.message : " "}
              </p>
        </div>
        <div >
          <label
            className="block text-orange-500 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Enter new password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="text"
            placeholder="Password"
            name="password"
            {...register("password",{
              required: "Required",
            })}
          />
           <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.password ? errors.password.message : " "}
              </p>
        </div>
        <div className="mb-2">
          <label
            className="block text-orange-500 text-sm font-bold mb-2"
            htmlFor="resetcode"
          >
            Confirm new password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmpassword"
            type="text"
            placeholder="Confirm New Password"
            name="confirmpassword"
            {...register("confirmpassword",{
              required: "Required",
            })}
          />
           <p style={{height:10}} className='text-red-500 text-xs italic mt-1'>
                {errors.confirmpassword ? errors.confirmpassword.message : " "}
              </p>
        </div>
        <div className="flex justify-center">
          <button
            className="bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Reset Password
          </button>
          <button
            className="mx-4 inline-block align-baseline font-bold text-sm text-orange-500 hover:text-orange-800 mt-2"
            // href="javascript:void(0)"
            onClick={() => {
              setOpenForgot(false);
              setOpen(true)
              // setOpenRegister(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  ) : (
    <div className="fixed flex justify-center items-center z-50 bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] shadow-lg w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen">
      <div className="max-w-sm w-full">
        <form
          onSubmit={handleSubmit(sendResetCode)}
          className="bg-white shadow-md rounded px-8 pb-8 pt-4 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-orange-500 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Enter registered email to get password reset code:
            </label>
            <div id="contact-success">
            {message !== undefined && (
              <div className="text-blue-700 px-4 my-3" role="alert">
                <p className="font-small">{message}</p>
                <p id="invalid-msg" className="text-sm"></p>
              </div>
            )}
            </div>
            <input
              className={
                errors.email
                  ? "border-red-500 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  : "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              }
              id="email"
              type="text"
              placeholder="Enter Email"
              name="email"
              {...register("email",{
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <p className="text-red-500 text-xs italic mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <button
              className="bg-gradient-to-r from-[#DA3C77] to-[#F7A63A] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Send Reset Code
            </button>
            <button
              className="mx-4 inline-block align-baseline font-bold text-sm text-orange-500 hover:text-orange-800 mt-2"
              onClick={() => {
                setOpenForgot(false);
                setOpen(true)
                // setOpenRegister(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
