import React from 'react';

const Modal = props => {
  return (
    <div
      className={`${
        props.showModal ? '' : 'hidden'
      } fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center`}>
      <div
        className={`${
          props.showModal ? '' : 'hidden'
        } fixed inset-0 transition-opacity`}>
        <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
      </div>

      <div
        className={`${
          props.showModal ? '' : 'hidden'
        } relative bg-white rounded-lg px-4 pt-4 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6`}>
        <div>
          <h3 className='text-sm leading-6 font-medium text-gray-700'>
            {props.title}
          </h3>
        </div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            onClick={() => {
              props.setShowModal(false);
            }}
            type='button'
            className='text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150'>
            <svg
              className='h-6 w-6'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 24 24'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
        <div className='w-auto'>{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
